
























































import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'
import { CartBillingAddress, ShippingMethod } from '../../../../contexts'
import { Inject, logger } from '../../../../support'
import { StructureConfigurable } from '../../../../support/mixins'
import { validatePayload } from '../../../shared/support/validate-payload'

import { NoteForm } from '../../molecules/NoteForm'
import { GuestDetailsFormFieldsetTwo } from '../../molecules/GuestDetailsFormFieldset'
import { CartMutationTypes, CheckoutStepCallback } from '../../contracts'
import { translateCheckoutPayloadToValidationObject } from '../../helpers/checkout.helpers'

import { AbstractStep } from '../AbstractStep'
import { Shippings } from '../Shippings'
import {
  customerDetailsValidatorsMap
} from '../CustomerDetailsStep/CustomerDetailsStep.helpers'
import {
  DELIVERY_DETAILS_STEP_COMPONENT_CONFIG_MAP,
  DELIVERY_DETAILS_STEP_COMPONENT_KEY
} from './DeliveryDetailsStep.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<DeliveryDetailsStep>({
  name: 'DeliveryDetailsStep',
  components: { NoteForm, Shippings, GuestDetailsFormFieldsetTwo },
  created (): void {
    this.config = this.getComponentConfig(
      DELIVERY_DETAILS_STEP_COMPONENT_KEY,
      { ...DELIVERY_DETAILS_STEP_COMPONENT_CONFIG_MAP }
    )
  }
})
export class DeliveryDetailsStep extends Mixins<AbstractStep, CartAnalyticsMixin,
  StructureConfigurable>(AbstractStep, CartAnalyticsMixin, StructureConfigurable) {
  @Prop({ type: Number, required: true })
  public currentStep!: number

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public errors: Record<string, string[]> | null = null
  public shippingErrors: string[] = []

  public isForBilling: boolean = true

  public get selectedShipping (): ShippingMethod | null {
    return this.payload.shipping
  }

  public set selectedShipping (value: ShippingMethod | null) {
    this.onChange('shipping', value)
  }

  public callbacks: CheckoutStepCallback[] = []

  public get note (): string {
    return this.payload.note
  }

  public set note (note: string) {
    this.onChange('note', note)
  }

  public get billingAddress (): CartBillingAddress | null {
    return this.cart.billingAddress
  }

  public get inputConfig (): boolean {
    return this.getConfigProperty('input')
  }

  public get addressFormData () {
    return {
      address: this.payload.address,
      user: this.payload.user
    }
  }

  /**
   * FIXME: Change AnyObject to a type.
   */
  public set addressFormData (values: AnyObject) {
    for (const [key, value] of Object.entries(values)) {
      this.onChange(key, value)
    }
  }

  public clearErrors (): void {
    this.errors = null
  }

  public static isValidStep (
    payload: AnyObject,
    translate: (key: string) => TranslateResult,
    locale: string,
    setErrors?: (errors: Record<string, string[]>) => void
  ): boolean {
    const result = validatePayload(
      payload,
      customerDetailsValidatorsMap,
      translate,
      locale
    )

    if (result.errors && setErrors) {
      setErrors(result.errors)
    }

    return !result.errors || Object.keys(result.errors).length === 0
  }

  public async submit (): Promise<void> {
    if (!this.selectedShipping) {
      this.shippingErrors = [this.$t('front.checkout.organisms.ShippingStep.error').toString()]

      return
    }

    this.shippingErrors = []

    this.$emit('saving', true)

    /**
     * FIXME
     *
     * Temporarily fixing bug #39196
     */
    let payload = this.payload
    if (this.currentStep === 2 || this.currentStep === 3 || this.currentStep === 4) {
      payload = {
        ...this.payload,
        acceptContent: true
      }
    }

    if (!this.isForBilling) {
      if (
        !DeliveryDetailsStep.isValidStep(
          translateCheckoutPayloadToValidationObject(payload),
          this.$t.bind(this),
          this.$i18n.locale,
          this.setErrors
        )
      ) {
        this.$emit('saving', false)
        return
      }

      try {
        await this.checkoutService.setShippingAddress(this.cart.id, {
          company: this.addressFormData.address.company,
          city: this.addressFormData.address.city,
          countryCode: this.addressFormData.address.country,
          lastname: this.addressFormData.user.firstName,
          firstname: this.addressFormData.user.lastName,
          postcode: this.addressFormData.address.postalCode,
          customerNotes: this.note,
          telephone: this.addressFormData.address.phone,
          street: [this.addressFormData.address.address],
          vatId: this.addressFormData.address.invoice?.nip
        })
      } catch (e) {
        logger(e, 'error')
      }
    }

    try {
      let cart = await this.checkoutService.setShippingMethod(this.cart.id, this.selectedShipping)
      for (const c of this.callbacks) {
        const returned = await c.callable.apply(c.onThis, c.args)
        if (typeof returned === 'object' && returned !== null) {
          cart = returned
        }
      }

      this.$store.commit(CartMutationTypes.SetCart, cart)

      this.eventBus.emit('app:checkout.addShippingInfo', {
        ...this.getBaseCheckoutPayload(this.cart),
        shippingTier: this.cart.selectedShippingMethod?.methodCode
      })
      this.nextStep()
    } catch (e) {
      logger(e, 'error')
      this.showToast((e as Error).message, 'warning')
    } finally {
      this.$emit('saving', false)
    }
  }

  public setErrors (errors: Record<string, string[]>): void {
    this.errors = errors
  }
}

export default DeliveryDetailsStep
