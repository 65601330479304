











/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
import { Component, PropSync, Vue } from 'vue-property-decorator'

import { NoteFormProps } from './NoteForm.contracts'

@Component({ name: 'NoteForm' })
export class NoteForm extends Vue {
  @PropSync('model', { required: false, default: '' })
  public _model!: NoteFormProps['model']
}

export default NoteForm
